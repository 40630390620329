import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
import { fileAPI } from 'api'
const { 
  v1: uuidv1,
  v4: uuidv4,
} = require('uuid');
export const getSurvey = async (data) => {
  try {
    const response = await client.post('/surveys/get-survey', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};
 

export const deleteSurveys = async (data) => {
  try {
    const response = await client.post('/surveys/delete-surveys', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const activeSurveys = async (data) => {
  try {
    const response = await client.post('/surveys/active-surveys', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const inActiveSurveys  = async (data) => {
  try {
    const response = await client.post('/surveys/inActive-surveys', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const checkSurveyUsedbyOtherProgram = async (data) => {
  try {
    const response = await client.post('/surveys/checkSurveyUsedbyOtherProgram', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmCareCampaignById = async (data) => {
  try {
    const response = await client.post('/surveys/getCmCareCampaignById', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const getCmCareCampaignByName = async (data) => {
  try {
    const response = await client.post('/surveys/getCmCareCampaignByName', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};
const uploadFile = async (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fileAPI.upload(file);
      console.log(res);
      resolve(res);
    } catch (error) {
      console.error("Upload failed:", error);
      reject(error);
    }
  });
};

export const surveySave = async (data) => {
  try {
    const formData = new FormData();
    if(data?.surveyQuestion&& data?.surveyQuestion?.length>0){
      for (let surveyQuestion of data?.surveyQuestion) {
        for (let surveyQuestionAnswer of surveyQuestion?.surveyQuestionAnswer) {
          if (isFile(surveyQuestionAnswer?.answerImgUrl)) {
            await uploadFile(surveyQuestionAnswer?.answerImgUrl).then(async res => {
              if(res){
                surveyQuestionAnswer['answerImgUrl']=res;
              } else{
                console.log('Upload failed:answerImgUrl');
              }
            }).catch(error => {
              console.log(error);
            });
          }
          for (let surveySubQuestion of surveyQuestionAnswer?.surveySubQuestion) {
            for (let subSurveyQuestionAnswer of surveySubQuestion?.surveyQuestionAnswer) {
              if (isFile(subSurveyQuestionAnswer?.answerImgUrl)) {
                await uploadFile(subSurveyQuestionAnswer?.answerImgUrl).then(async res => {
                  if(res){
                    subSurveyQuestionAnswer['answerImgUrl']=res;
                  } else{
                    console.log('Upload failed:answerImgUrl');
                  }
                }).catch(error => {
                  console.log(error);
                });
              }
            }
          }
        };
      };
      // data?.surveyQuestion.map((surveyQuestion)=>{
      //   surveyQuestion?.surveyQuestionAnswer?.map((surveyQuestionAnswer)=>{
      //     if (isFile(surveyQuestionAnswer?.answerImgUrl)) {
      //       const uuid = uuidv4();
      //       surveyQuestionAnswer['answerImgKeyIdx'] = uuid;
      //       formData.append(uuid, surveyQuestionAnswer?.answerImgUrl)
      //     }
      //     surveyQuestionAnswer?.surveySubQuestion?.map((surveySubQuestion) => {
      //       surveySubQuestion?.surveyQuestionAnswer?.map((subSurveyQuestionAnswer)=>{
      //         if (isFile(subSurveyQuestionAnswer?.answerImgUrl)) {
      //           const uuid = uuidv4();
      //           subSurveyQuestionAnswer['answerImgKeyIdx'] = uuid;
      //           formData.append(uuid, subSurveyQuestionAnswer?.answerImgUrl)
      //         }
      //       })
      //     })
      //   })
      // })
    }
    formData.append("survey", JSON.stringify(data.id?updateBy(data):createBy(data)))
    if (isFile(data?.surveyImageUrl)) {
      formData.append("surveyImageUrl", data?.surveyImageUrl)
    }
    const response = await client.post('/surveys/save', formData);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const getSurveyById = async (data) => {
  try {
    const response = await client.post('/surveys/get-survey-detail', data);
    return response.data;
  } catch (error) {
    console.log(error) 
    throw error;
  }
};




export const getParameterGroup = async (data) => {
  try {
    const response = await client.post('/surveys/paramter-group', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getParameterInterest = async (data) => {
  try {
    const response = await client.post('/surveys/paramter-interest', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};



export const getParameterAttribute1 = async (data) => {
  try {
    const response = await client.post('/surveys/paramter-attribute1', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const survey = async (data) => {
  try {
    console.log('survey request=',data);
    const response = await client.post('/surveys/survey', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const getLovSurvey = async (data) => {
  try {
    console.log('getLovSurvey request=',data);
    const response = await client.post('/surveys/get-lov-survey', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

