import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
import client2 from './instanceNoLodding'


export const getCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContents', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmContentsByCodeOrName = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentsByCodeOrName', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const generateLastCode = async (code) => {
  try {
    const response = await client.post('/cm-content/generateLastCode', { code });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmContentById = async (id) => {
  try {
    const response = await client2.post('/cm-content/getCmContentById', { id });
    console.warn('responseresponse=',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmContentBy = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const getCmContentCareMessage = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentCareMessage', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const createCmContent = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(createBy(data)))
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
    //   formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    // }
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
    //   formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].videoFileUrl)
    // }

    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl)
    }

    //console.log("backend===>",data)
    
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineImageUrl) && item.lineType === "I"){
        formData.append(`L-lineImageUrl-${item.lineOrderNumber}`, item.lineImageUrl)
      }
    })
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineVideoPreviewUrl) && item.lineType === "V"){
        formData.append(`L-lineVideoPreviewUrl-${item.lineOrderNumber}`, item.lineVideoPreviewUrl)
      }
    })
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineVideoUrl) && item.lineType === "V"){
        formData.append(`L-lineVideoUrl-${item.lineOrderNumber}`, item.lineVideoUrl)
      }
    })

    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.length > 0){
        data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item)=>{
          if(item?.lineType && item.lineType === "C"){
            const card = item.cmContentLineCard;
            console.log("ok")
            Object.keys(card).forEach(key => {
              console.log("key---->",card[key].lineCardImageUrl)
              if(isFile(card[key].lineCardImageUrl)){
                formData.append(`L-cardFile-${item.lineOrderNumber}-${card[key].lineCardOrderNumber}`, card[key].lineCardImageUrl)
               console.log("data---->",card[key].lineCardImageUrl)
              }
          });
          }
        })
        
    }

    let indexEmail = 0;
    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles){
        const emailData = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles
        Object.keys(emailData).forEach(key => {
          //console.log("data---->",emailData[key])
          if(emailData[key]?.fileUrl?.name){
            formData.append(`E-file${indexEmail}`, emailData[key].fileUrl)
           // console.log("data---->",emailData[key])
           indexEmail++;
          }
          
      });
    }


    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    const response = await client.post('/cm-content/createCmContent', formData);
    // const response = "";
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCmContent = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(updateBy(data)))
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
    //   formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    // }
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
    //   formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].videoFileUrl)
    // }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl)
    }

    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineImageUrl) && item.lineType === "I"){
        formData.append(`L-lineImageUrl-${item.lineOrderNumber}`, item.lineImageUrl)
      }
    })
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineVideoPreviewUrl) && item.lineType === "V"){
        formData.append(`L-lineVideoPreviewUrl-${item.lineOrderNumber}`, item.lineVideoPreviewUrl)
      }
    })
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineVideoUrl) && item.lineType === "V"){
        formData.append(`L-lineVideoUrl-${item.lineOrderNumber}`, item.lineVideoUrl)
      }
    })

    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.length > 0){
        data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item)=>{
          if(item?.lineType && item.lineType === "C"){
            const card = item.cmContentLineCard;
            console.log("ok")
            Object.keys(card).forEach(key => {
              console.log("key---->",card[key].lineCardImageUrl)
              if(isFile(card[key].lineCardImageUrl)){
                formData.append(`L-cardFile-${item.lineOrderNumber}-${card[key].lineCardOrderNumber}`, card[key].lineCardImageUrl)
               console.log("data---->",card[key].lineCardImageUrl)
              }
          });
          }
        })
        
    }

    let indexEmail = 0;
    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles){
        const emailData = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles
        Object.keys(emailData).forEach(key => {
          console.log("data---->",emailData[key])
          if(emailData[key]?.fileUrl?.name){
            formData.append(`E-file${indexEmail}`, emailData[key].fileUrl)
           // console.log("data---->",emailData[key])
           indexEmail++;
          }
          
      });
    }


    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

    const response = await client.post('/cm-content/updateCmContent', formData);

    return response.data;



    // const response = await client.post('/cm-content/updateCmContent', data);

    // return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/activeCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/inActiveCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/deleteCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}